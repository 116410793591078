table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
/*  width: auto; */
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #b92327;
  color: white;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

table td {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

select.MuiSelect-select {
  font-family: "Roboto Condensed", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
